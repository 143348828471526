<template>
  <v-card class="tertiary">
    <v-card-title class="text-h5 primary--text">
      {{isNew ? 'Novo Usuário' : 'Editar Usuário'}}

      <v-spacer></v-spacer>

      <label for="status" class="text-body-2 mr-2">{{user.status ? 'Ativo' : 'Inativo' }}</label>
      <v-switch id="status" v-model="user.status"/>
    </v-card-title>

    <v-card-text>
      <form class="py-5">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="user.name"
              color="primary"
              label="Nome"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="user.email"
              type="email"
              color="primary"
              label="Email"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="user.profile_id"
              :items="profiles"
              item-text="profile_name"
              item-value="id"
              color="primary"
              label="Tipo de perfil"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="user.contact"
              color="primary"
              label="Contato"
              v-mask="['(##) ####-####', '(##) #####-####']"
              outlined
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              color="primary"
              label="Whatsapp"
              v-model="user.is_whatsapp"
              dense
            />
          </v-col>
        </v-row><v-row>
          <v-col cols="6">
            <v-text-field
              v-model="user.password"
              :type="showPass ? 'text' : 'password'"
              color="primary"
              label="Senha"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              :type="showPass ? 'text' : 'password'"
              color="primary"
              label="Senha"
              :rules="[value => value === user.password || 'Senha não confere']"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPass = !showPass"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="primary"
        text
        @click="$emit('close')"
      >
        Cancelar
      </v-btn>
      <v-btn
        v-if="isNew"
        color="primary tertiary--text"
        @click="create"
      >
        <v-icon small>mdi-check</v-icon>
        Cadastrar
      </v-btn>
      <v-btn
        v-else
        color="primary tertiary--text"
        @click="update"
      >
        <v-icon small>mdi-check</v-icon>
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    isNew: Boolean,
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showPass: false,
    profiles: [],
  }),
  methods: {
    getProfiles() {
      this.$api.get('profiles')
        .then(res => {
          this.profiles = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    create() {
      this.$emit('create')
      this.$emit('close')
    },
    update() {
      this.$emit('update')
      this.$emit('close')
    },
  },
  mounted() {
    this.getProfiles()
  },
}
</script>